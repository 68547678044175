
.container {
    padding: 32px;
    background-color: #010606;
    overflow-x: hidden;
    overflow-y: visible;
}

body {
    background-color: #010606;
    overflow-x: hidden;
    overflow-y: visible;
}

.contact__form .form-control {
    padding: 1.375rem .75rem;
    line-height: 1.5;
    color: #010606;
    background-color: white;
    border-radius: 0 !important;
    border: 1px solid #010606;
}

.contact__form input.form-control {
    margin-bottom: 2em;
    height: calc(2.5em + .75rem + 2px);
    color: #010606;
}

.color_sec {
    color: white;
    text-decoration: none;
}

.d-flex{
    background-color: #101522;
    padding: 32px;
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 48px;
    gap:20px;
}

.mb-5.mt-3{
    color: white;
}

.contact__form span.errorMessage {
    color: red;
}